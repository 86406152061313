
import { ClaimCategory } from "@/types";
import { Component, Vue } from "vue-property-decorator";

import * as ClaimCategoryService from "@/services/ClaimCategoryService";
import { ROOT_ACTIONS } from "@/store/actions";

@Component
export default class Index extends Vue {
    categories: ClaimCategory[] = [];

    async mounted() {
        this.categories = await ClaimCategoryService.GetClaimCategories();
    }

    loading = {
        categories: false,
        delete: false,
        edit: false,
    }

    dialogs = {
        edit: false,
    }


    newName = '';
    selectedCategory = '';

    async deleteCategory(id: string) {
        try {
            this.loading.categories = true;

            await ClaimCategoryService.DeleteClaimCategory(id);

            this.categories = await ClaimCategoryService.GetClaimCategories();

            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: "success",
                text: "Categorie verwijderd",
            });

        } catch (error) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: "error",
                text: error,
            });
        } finally {
            this.loading.categories = false;
        }
    }

    async editCategory(id: string) {
        this.selectedCategory = id;
        this.newName = this.categories.find(x => x.id === id)?.nl ?? '';
        this.dialogs.edit = true;
    }

    async submitEdit() {
        try {
            this.loading.edit = true;
            this.loading.categories = true;

            await ClaimCategoryService.UpdateClaimCategory({
                id: this.selectedCategory,
                nl: this.newName,
            });

            this.categories = await ClaimCategoryService.GetClaimCategories();

            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: "success",
                text: "Categorie bewerkt",
            });

        } catch (error) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: "error",
                text: error,
            });
        } finally {
            this.loading.categories = false;
            this.dialogs.edit = false;
            this.loading.edit = false;
        }
    }
}
